"use client";
import * as React from "react";
import { IProfile } from "@/types/profile";
import { ArrowRight } from "lucide-react";
import Image from "next/image";
import { ProfileModal } from "../modal/profile";

export const ProfileSmallCard = ({ entry }: { entry: IProfile }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      {open && <ProfileModal profile={entry} onClose={() => setOpen(false)} />}
      <div className="bg-line-pattern bg-[length:600px_600px] bg-repeat rounded-md border p-4 cursor-pointer">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1">
            <Image
              src={`${entry.image.url}?w=100&h=100&fit=fill&fm=avif`}
              alt={entry.image.title}
              width={100}
              height={100}
              className="rounded-sm"
            />
          </div>
          <div className="col-span-10 flex justify-between items-center">
            <h5>
              {entry.firstname}{" "}
              <span className="font-medium">{entry.lastname}</span>
            </h5>
          </div>
          <div className="col-span-1 flex justify-end items-center">
            <button onClick={() => setOpen(true)}>
              <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
