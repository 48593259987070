"use client";
import React from "react";
import ReactPlayer from "react-player/youtube";

interface YouTubePlayerProps {
  url: string;
}

const YouTubePlayer: React.FC<YouTubePlayerProps> = ({ url }) => {
  return (
    <div className="aspect-video _w-[100%+6rem] -ml-6 -mr-6 xl:-ml-16 xl:-mr-16 my-10">
      <ReactPlayer
        className="react-player aspect-video"
        url={url}
        width="100%"
        height="100%"
        controls
      />
    </div>
  );
};

export default YouTubePlayer;
